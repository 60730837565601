<!--
 * @Author: 月魂
 * @Date: 2022-11-11 14:08:19
 * @LastEditTime: 2022-11-12 12:07:43
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\views\Ingredients\Material.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <Navigation :title="material.name" />
      <img src="../../assets/temp/ingredients.png" alt="" class="img top-bg">
      <img src="../../assets/temp/ingredients-pc.png" alt="" class="img top-bg pc">
      <div class="m-cont">
        <div class="item">
          <img :src="domain + material.imgUrl" alt="" class="img">
          <div class="bottom">
            <div class="des" v-for="(des, index) in material.des" :key="index">{{ des }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Tab, Tabs } from 'vant';
import Navigation from '@/components/Navigation'

Vue.use(NavBar).use(Tab).use(Tabs);

export default {
  name: 'IngredientsMaterial',
  components: {
    Navigation
  },
  data() {
    return {
      active: 0,
      domain: '',
      material: {}
    }
  },
  mounted() {
    this.domain = this.$t('domain')
    this.material = this.$t(`ingredients.${this.$route.params.id}`).list[this.$route.params.sId]
    this.wxShare(this.material.name, this.material.name)
  },
  watch: {
    '$i18n.locale'() {
      this.material = this.$t(`ingredients.${this.$route.params.id}`).list[this.$route.params.sId]
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #06062B;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  .cont {
    max-width: 1200PX;
    margin: auto;
    z-index: 1;
    .top-bg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .pc {
      display: none;
    }
    @media (min-width: 1000PX) {
      .top-bg {
        display: none;
      }
      .pc {
        display: block;
      }
    }
    .m-cont {
      padding: 16px;
      font-size: 12px;
    }
    .item {
      margin-bottom: 16px;
      border-radius: 15px;
      background: #111135;
      .img {
        display: block;
        width: 100%;
        border-radius: 15px;
      }
      .bottom {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        line-height: 20px;
        .des {
          color: #999;
        }
      }
    }
  }
}
</style>